<template>
  <div class="container">
    <div class="customer" ref="cTomer">
      <img src="../assets/playedt.png" class="customer_top" alt="" />
      <div class="customer_time" ref="cTime">
        <span>在线时间 (北京时间)</span>
        <span class="customer_time_btn">10: 00 ~ 18: 00</span>
      </div>
    </div>
    <div class="customer_info">
      <img src="../assets/mmexport1621312340952.png" class="kefu" alt="" v-image-preview>
      <span class="kefutext">微信识别二维码联系客服</span>
      <!-- <div class="customer_service" @click="Consulting">
        <img src="../assets/kfu.png" class="customer_icon" alt="" />
        <span class="customer_text">直接咨询</span>
      </div>
      <div class="customer_chat" v-for="item in serverInfoList" :key="item.id" @click="clickHandle(item.number)">
        <div class="customer_chat_left">
          <img :src="item.icon" class="customer_chat_icon" alt="" />
          <span>{{item.title}}</span>
        </div>
        <span>{{item.number}}</span>
        <span class="customer_chat_copy">复制</span>
      </div> -->
    </div>
    <div class="customer_view">
      <div class="customer_line customer_t">
        <img src="../assets/pay.png" class="customer_pay" alt="" />
        <span class="customer_title">充值问题</span>
      </div>
      <div class="customer_line customer_size">
        <span @click="onclick('recharge')">充值/储值未到账</span>
        <span @click="onclick('discount')">充值如何打折</span>
      </div>
      <div class="customer_line customer_size">
        <span @click="onclick('quota')">充值限额怎么办</span>
        <span @click="onclick('overseas')">充值福利</span>
      </div>
    </div>
    <div class="customer_view">
      <div class="customer_line customer_t">
        <img src="../assets/anquan.png" class="customer_pay" alt="" />
        <span class="customer_title">账号问题</span>
      </div>
      <div class="customer_line customer_size">
        <span @click="onclick('account')">忘记账号</span>
        <span @click="onclick('password')">忘记密码</span>
      </div>
      <div class="customer_line customer_size">
        <span @click="onclick('pirate')">账号被盗</span>
        <span @click="onclick('hutong')">账号互通</span>
      </div>
    </div>
    <!-- height:100px -->
    <div class="customer_view" style="margin-bottom: 70px;">
      <div class="customer_line customer_t">
        <img src="../assets/game.png" class="customer_pay" alt="" />
        <span class="customer_title">游戏问题</span>
      </div>
      <div class="customer_line customer_size">
        <span @click="onclick('login')">下载登录异常</span>
        <span @click="onclick('tousu')">刷屏广告投诉</span>
        <span @click="onclick('feigf')">能否进入非官方群</span>
        <!-- <span></span> -->
      </div>
      <div class="customer_line customer_size">
        <span @click="onclick('pc')">电脑上如何玩手游</span>
        <span @click="onclick('maimai')">游戏账号可否买卖</span>
        <span @click="onclick('heiping')">遇到黑屏怎么办</span>
        <!-- <span></span> -->
      </div>
    </div>
    <!-- -->
    <van-action-sheet
      v-model="model"
      :title="problemTitle"
      safe-area-inset-bottom
    >
      <div class="customer_content">
        <p class="customer_txt" v-for="item in problemContent" :key="item.id">
          {{ item }}
        </p>
      </div>
    </van-action-sheet>
  </div>
</template>
<script>
import Hot from "@/components/Hot.vue";

import QQImage from '@/assets/qq.png'
import WeChatImage from '@/assets/wechat.png'
import PhoneImage from '@/assets/phone.png'
import { mapGetters } from "vuex";
import { Toast } from 'vant';

export default {
  name: "Customer",
  components: {
    Hot,
  },
  data() {
    return {
      serverInfoList: [
        {
          id: 1,
          icon: QQImage,
          title: 'QQ',
          number: '2339533370'
        },
        // {
        //   id: 2,
        //   icon: WeChatImage,
        //   title: '微信',
        //   number: '18530266871'
        // },
        {
          id: 3,
          icon: PhoneImage,
          title: '手机',
          number: '19919912306'
        }
      ],
      problemTitle: "",
      problemContent: [],
      model: false,
      problem: {
        recharge: {
          title: '充值/储值未到帐如何处理？',
          lists: [
            "查看订单充值是否为糖豆，如是糖豆，充值后在游戏页面使用糖豆支付即可",
            "如不是糖豆，可联系客服进行处理（需提供角色名称，区服，以及充值订单详情）"
          ]
        }, // 充值未到账
        quota: {
          title: '支付限额了怎么办？',
          lists: [
            "部分玩家如遇到充值限额问题说明：【微信虚拟充值】额度5000元-10000元/每日。如需要大额充值，可使用【支付宝充值】，或更换其它微信支付哦。如果以上方法都不方便，也可第一时间点击APP上方的【客服】图标，进行微信转帐后代充值。"
          ]
        }, // 充值限额
        discount: {
          title: '游戏充值有折扣活动吗？',
          lists: [
            "不同的游戏会有不同的充值折扣。有折扣的游戏，我们会在游戏界面进行【明显标注】，请注意留意哦。"
          ]
        }, // 打折
        overseas: {
          title: '充值福利',
          lists: [
            '有福利的游戏下方会有详细介绍，如达到条件后，可联系客服进行申请'
          ]
        }, // 海外
        account: {
          title: '我忘记了游戏帐号/忘记密码怎么办？',
          lists: [
            '之前绑定了手机号，操作步骤如下：',
            '点击游戏盒-我的-退出登录-点击登录/注册-点击找回密码',
            '如没有绑定手机号，可联系客服（提供账号以及充值订单详情若干张）',
          ]
        }, // 账号
        pirate: {
          title: '帐号被盗了能不能找回？',
          lists: [
            '帐号被盗原因只有一个，就是亲将帐号密码信息泄露给了其他玩家，请主动和客服进行说明。',
            '并不存在所谓木马病毒盗号等问题。',
            '报备后会进行审核，判定是否被盗。如发现私下交易问题，则不予处理，请直接与玩家进行沟通。',
            '我们明令禁止玩家通过线下进行不安全交易。',
            '如没有上述问题，请于第一时间点击APP上方的【客服】图标，提供【游戏名称+几区+游戏角色名】信息，及最新充值【订单若干张】，我们会在工作时间内第一时间处理。'
          ]
        }, // 被盗
        password: {
          title: '我忘记了游戏帐号/忘记密码怎么办？',
          lists: [
            '之前绑定了手机号，操作步骤如下：',
            '点击游戏盒-我的-退出登录-点击登录/注册-点击找回密码',
            '如没有绑定手机号，可联系客服（提供账号以及充值订单详情若干张）',
          ]
        }, // 密码
        login: {
          title: '下载登入异常/黑屏无法进入游戏了怎么办？',
          lists: [
            '如遇到登陆异常问题，请于第一时间点击APP上方的【客服】图标，提供【游戏名称+几区+游戏角色名】信息，我们会在工作时间内第一时间处理。'
          ]
        }, // 登录
        pc: {
          title: '电脑上如何玩手游？',
          lists: [
            '部分游戏支持安卓包下载，请于第一时间点击APP上方的【客服】图标，问询是否有安卓包。留言请注明：游戏名称+几区+游戏角色名，我们会在工作时间内第一时间处理。推荐电脑下载【夜神模拟器】，并安装游戏包，输入原帐号密码即可进行角色互通。切记仔细核实帐号密码哦。'
          ]
        }, // 计算机
        hutong: {
          title: '帐号互通',
          lists: [
            '小程序与游戏盒账号可互通',
            '在小程序上查看账号后，即可登录到游戏盒上',
            '（如不会查看，可联系客服）'
          ]
        },
        tousu: {
          title: '刷屏广告如何投诉',
          lists: [
            '如遇玩家在聊天（世界频道）中发布虚假广告，请第一时间进行投诉，我们会进行封号处理。对于垃圾信息我们坚决第一时间处理。'
          ]
        },
        feigf: {
          title: '能否进入非官方群',
          lists: [
            '不要被任何形式的私聊诱导进入任何非官方群，以免造成财产的巨额损失。',
            '请不要相信任何线下形式的充值折扣返利活动，一切以官方通报为准。',
            '防骗指南：通常群里全是骗子号哦，假装是某某区大V，线下充值享受什么折扣，骗你线下转帐。该骗术很拙劣，谨防上当。',
            '请不要下载安装任何玩家私下给予的链接，以免造成财产损失和信息泄露。'
          ]
        },
        kaifu: {
          title: '游戏开服问题',
          lists: [
            '每个游戏自动开服，请留意客服无从知晓'
          ]
        },
        maimai: {
          title: '游戏帐号可否买卖',
          lists: [
            '游戏帐号私下交易不受任何保护，骗子会通过多种方式进行恶意找回。很可能造成人财两空的局面。'
          ]
        },
        heiping: {
          title: '遇到黑屏怎么办',
          lists: [
            '退出登录，重新登录进入游戏',
            '可切换网络后进入游戏',
            '如以上操作后依旧黑屏，可联系客服进行处理'
          ]
        }
      },
    };
  },
  mounted() {
    if(judgeBigScreen()) {
      this.$refs.cTomer.className = "customer customerAndroid"
      this.$refs.cTime.className = "customer_time customerTimeAndroid"
    }
  },
  computed: {
    ...mapGetters(["CYBoxUser"]),
  },
  methods: {
    onclick(id) {
      this.problemContent = this.problem[id].lists
      this.problemTitle = this.problem[id].title
      this.model = true
    },
    clickHandle(number) {
      var oInput = document.createElement('input');
      oInput.value = number;
      document.body.appendChild(oInput);
      oInput.select(); // 选择对象
      document.execCommand("Copy");
      oInput.style.display = 'none'
      document.body.removeChild(oInput)
      Toast('内容已复制');
    },
    Consulting() {
      try {
        window.webkit.messageHandlers.CYPay.postMessage("https://h5sdk.76ab.com/miniH5/kefu.html");
      } catch (error) {}
    }
  }
};
</script>
<style scoped lang="stylus">
@import '../components/common.styl';

.customer {
  position: relative;
  height: calc(106px + constant(safe-area-inset-bottom));
  height: calc(106px + env(safe-area-inset-bottom));
  width: 100%;
  overflow hidden
}

.customer_top {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.customer_time {
  height: 100%;
  font-size: 20px;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: constant(safe-area-inset-bottom);
  padding-top: env(safe-area-inset-bottom);

  span {
    z-index: 1;
  }
}

.customer_time_btn {
  margin-top: 5px;
}

.customer_info {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content center;
  height: 217px;
  width: $viewWidth;
  background: $themeWhite;
  border-radius: 5px;
  margin: 0 auto;
  position: relative;
  top: -18px;
}
.kefu {
  height 86%
}
.kefutext {
  font-size 14px
  color #999
}
.customer_service {
  border: 1px solid #f72828;
  border-radius: 5px;
  height: 26px;
  width: 92px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 28px 0;
}

.customer_icon {
  width: 18px;
  height: 17px;
}

.customer_text {
  padding-left: 2px;
  color: #f72828;
  font-size: 14px;
}

.customer_chat {
  height: 34px;
  width: 96%;
  background: #f4f4f4;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 15px;
  font-weight: 600;
  margin: 3px 0;
}

.customer_chat_left {
  display: flex;
  align-items: center;
  margin-left: 6px;
}

.customer_chat_icon {
  height: 24px;
  width: 24px;
  margin-right: 8px;
}

.customer_chat_copy {
  margin-right: 10px;
  font-size: 14px;
  width: 36px;
  color: #f72828;
  border: 1px solid #f72828;
  border-radius: 5px;
  display flex
  justify-content center
  text-align: center;
}

.customer_pay {
  width: 28px;
  height: 28px;
}

.customer_title {
  font-size: 14px;
  font-weight: 600;
}

.customer_view {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: $viewWidth;
  margin: 0 auto 10px;
  background: $themeWhite;
  height: 74px;
  border-radius: 5px;
}

.customer_line {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.customer_t {
  align-items: center;
}

.customer_size {
  font-size: 12px;
  text-align: left;
  width: 120px;
  span {
    height: 20px;
  }
}

.customer_content {
  height: 80vh;
  background: #f2f2f6;
}

.customer_txt {
  font-size: 14px;
  padding: 4px 16px;
  background: #f2f2f6;
  line-height 20px;
  letter-spacing: 0.5px
}

</style>
